import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalEdit: false,
    dataUtama: {
      id: '',
      sapk: {
        id: ''
      },
      nipBaru: '',
      nipLama: ''
    },
    rwpenghargaan: {
      id: '',
      sapk: {
        id: null
      },
      penghargaan: {
        id: null,
        nama: null
      },
      suratKeputusan: {
        pejabat: '',
        nomor: '',
        tanggal: ''
      },
      tahun: null,
      pnsUserId: null,
      NCSISTIME: null
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalEdit = value
    },
    editModal(state, value) {
      state.rwpenghargaan = value
    },
    editDatautama(state, value) {
      state.dataUtama = value
    }
  }
})
