<template>
  <div>
    <div>
      <v-alert v-model="alertBerhasil" type="success" dense dismissible>
        {{ alertMassage }}
      </v-alert>
      <v-alert v-model="alertGagal" type="error" dense dismissible>
        {{ alertMassage }}
      </v-alert>
    </div>

    <div>
      <v-data-table
        :headers="headers"
        :items="rwpenghargaans"
        :mobile-breakpoint="0"
        :items-per-page="-1"
        :loading="ldata"
        hide-default-footer
        style="text-overflow: ellipsis; white-space: nowrap"
      >
        <template v-slot:top>
          <rwpenghargaanModalEdit />
          <rwpenghargaanModalHapus />
        </template>
        <template v-slot:[`item.sk`]="{ item }">
          <div class="py-2">
            Pejabat : {{ item.suratKeputusan.pejabat }} <br />
            Nomor : {{ item.suratKeputusan.nomor }} <br />
            Tanggal : {{ item.suratKeputusan.tanggal }}
          </div>
        </template>

        <template v-slot:[`item.file`]="{ item }">
          <div v-if="item.doc != null">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="download(item.id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="red"> mdi-file-pdf-box </v-icon>
                </v-btn>
              </template>
              <span>Dokumen</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-1" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="modalPDF" :max-width="dekstop">
      <v-card height="90vh" style="overflow: hidden">
        <v-toolbar dark color="primary" dense flat>
          <v-toolbar-title>{{ namaPDF }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closePDF()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card flat class="pa-2">
          <v-progress-linear
            indeterminate
            color="primary"
            v-if="lbtn"
          ></v-progress-linear>

          <div v-else>
            <embed
              :src="defaultPDF"
              type="application/pdf"
              style="height: 80vh; width: 100%"
            />
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import rwpenghargaanModalEdit from "@/components/asn/profil/rwpenghargaan/ModalEdit";
import rwpenghargaanModalHapus from "@/components/asn/profil/rwpenghargaan/ModalHapus";

import modal_editState from "@/store/asn/rwpenghargaan/modal_edit";
import modal_hapusState from "@/store/asn/rwpenghargaan/modal_hapus";
import refreshView from "@/store/asn/rwpenghargaan/viewRwpenghargaan";

export default {
  name: "rwpenghargaan",

  components: {
    rwpenghargaanModalEdit,
    rwpenghargaanModalHapus,
  },

  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refreshView.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshView.state.success;
      },

      set(value) {
        refreshView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshView.state.alertBerhasil;
      },

      set(value) {
        refreshView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshView.state.alertGagal;
      },

      set(value) {
        refreshView.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  async mounted() {
    this.get_nip = this.$route.params.id;
    this.getData();
  },

  data: () => ({
    token: Cookie.get("token"),
    rwpenghargaans: [],
    rwpenghargaan: {},

    ldata: true,
    lbtn: false,
    loading: false,
    modalPDF: false,

    dekstop: "75%",
    defaultPDF: "",
    namaPDF: "",

    //Kebutuhan Edit
    editedIndex: -1,
    hapusIndex: -1,

    headers: [
      { text: "Bintang Tanda Jasa", align: "start", value: "penghargaan.nama" },
      { text: "Surat Keputusan (SK)", value: "sk" },
      {
        text: "File",
        value: "file",
        align: "center",
        width: "80px",
        sortable: false,
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
        width: "80px",
        sortable: false,
      },
    ],
  }),

  methods: {
    getData() {
      this.ldata = true;
      const url = process.env.VUE_APP_ASN + "penghargaan/nip/" + this.get_nip;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.ldata = false;
          refreshView.commit("refreshData", false);
          this.rwpenghargaans = response.data.mapData.RwPenghargaan;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.rwpenghargaans.indexOf(item);
      this.rwpenghargaan = Object.assign({}, item);
      modal_editState.commit("toggleModal", true);
      modal_editState.commit("editModal", Object.assign({}, item));
    },

    deleteItem(item) {
      this.hapusIndex = this.rwpenghargaans.indexOf(item);
      this.rwpenghargaan = Object.assign({}, item);
      modal_hapusState.commit("toggleModal", true);
      modal_hapusState.commit("hapusModal", Object.assign({}, item));
    },

    download(value) {
      this.lbtn = true;
      this.modalPDF = true;
      this.namaPDF = "Dokumen";

      const url =
        process.env.VUE_APP_API +
        "/idasn/penghargaan/doc/" +
        this.get_nip +
        "/" +
        value +
        "/doc";

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
          responseType: "blob",
        })
        .then((res) => {
          this.defaultPDF = URL.createObjectURL(res.data);
          this.lbtn = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout(this.token)
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    closePDF() {
      this.modalPDF = false;
    },
  },
};
</script>
