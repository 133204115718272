import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        ModalToSIMASN: false,
        dataUtama: {
            id: '',
            SAPK: {
                id: ''
            },
            nipBaru: '',
            nipLama: ''
        },
        rwpenghargaan: {
            id: '',
            sapk: {
                id: null
            },
            penghargaan: {
                id: null,
                nama: null
            },
            suratKeputusan: {
                pejabat: '',
                nomor: '',
                tanggal: ''
            },
            tahun: null,
            pnsUserId: null,
            NCSISTIME: null
        }
    },

    mutations: {
        toggleModal(state, value) {
            state.ModalToSIMASN = value
        },
        tosimasnModal(state, value) {
            state.rwpenghargaan = value
        },
        Datautama(state, value) {
            state.dataUtama = value
        }
    }
})
