<template>
  <div>
    <v-card outlined flat>
      <v-app-bar color="primary" short dense dark flat>
        <v-toolbar-title>Riwayat Penghargaan</v-toolbar-title>
        <v-spacer></v-spacer>

        <div class="mr-3 mt-1">
          <rwpenghargaanModalAdd />
        </div>

        <div style="max-width: 300px;">
          <v-tabs v-model="tab" right dark background-color="primary">
            <v-tab href="#tab-1" @click="getSIMASN()">
              <h5>IDASN</h5>
            </v-tab>

            <v-tab href="#tab-2" @click="getSAPK()">
              <h5>SIASN</h5>
            </v-tab>
          </v-tabs>
        </div>
      </v-app-bar>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item value="tab-1" class="pa-5">
          <rwpenghargaanSIMASN />
        </v-tab-item>
        <v-tab-item value="tab-2" class="pa-5">
          <rwpenghargaanSAPK />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import getData from '@/store/asn/rwpenghargaan/tabs'
import rwpenghargaanSIMASN from '@/components/asn/profil/rwpenghargaan/rwpenghargaan'
import rwpenghargaanSAPK from '@/components/asn/profil/rwpenghargaan/SAPK_rwpenghargaan_Modal'
import rwpenghargaanModalAdd from '@/components/asn/profil/rwpenghargaan/ModalAdd'

export default {
  name: 'DataUtama',

  components: {
    rwpenghargaanSIMASN,
    rwpenghargaanSAPK,
    rwpenghargaanModalAdd
  },

  computed: {
    dataSAPK: {
      get() {
        return getData.state.tabSAPK
      },

      set(value) {
        getData.commit('getSAPK', value)
      }
    }
  },

  data: () => ({
    tab: null
  }),

  methods: {
    getSAPK() {
      getData.commit('getSAPK', true)
      getData.commit('getSIMASN', false)
    },
    getSIMASN() {
      getData.commit('getSIMASN', true)
      getData.commit('getSAPK', false)
    }
  }
}
</script>
