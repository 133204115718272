import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalHapus: false,
    rwpenghargaan: {
      id: '',
      sapk: {
        id: null
      },
      penghargaan: {
        id: null,
        nama: null
      },
      suratKeputusan: {
        pejabat: '',
        nomor: '',
        tanggal: ''
      },
      tahun: null,
      pnsUserId: null,
      NCSISTIME: null
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalHapus = value
    },
    hapusModal(state, value) {
      state.rwpenghargaan = value
    },
  }
})
